<template lang="pug">
	transition(name="slide-fade")
		div
			loading(:active.sync="busy", :is-full-page="true")
			.widget(v-if="publisher")
				.widget-header
					h1.title Publisher API Preview
				.widget-body
					form.form.form-inline.ov-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
						a.toggle-filters(href="javascript: void(0);", @click="toggleFilters()")
							span Filters
							i.la.la-chevron-up
						.form-row-main
							.form-group
								input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword", v-debounce:300ms="loadData")
							.form-group
								button.btn.btn-primary(type="button", @click="loadData()") Go
						.form-row-end
							.form-group
								button.btn.btn-primary(type="button", @click="autoClean()") AutoClean

					p.clearfix &nbsp;

					.ov-table-wrapper
						table.table.table-bordered
							thead
								tr
									th ID
									th Name
									th Country
									th Platform
									th Payout
									th Model
									th Cap
									th Bundle / Package
									th BlackList
							tbody
								tr(v-if="records.length===0")
									td(colspan="6") No matching records were found
								tr(v-for="r in records", v-bind:key="r.id")
									td {{ r.id }}
									td {{ r.name }}
									td {{ r.country }}
									td {{ r.platform }}
									td {{ r.payout }} {{ r.currency }}
									td {{ r.payout_type }}
									td {{ r.daily_cap }}
									td {{ r.bundle_id }} / {{ r.package_id }}
									td {{ r.black_list.join(', ') }}

						paginate(:paginator="paginate", @update-page-size="loadData")
</template>
<script>
import Vue from 'vue';

export default {
  name: 'PublisherAPIPreview',
  async beforeRouteEnter(to, from, next) {
    let publisher = {};
    let entityId = +to.params.id;
    try {
      publisher = await Vue.ovData.publisher.get(entityId, true);
    } catch (e) {
      return next(false);
    }
    next((vm) => {
      vm.publisher = publisher;
      vm.loadData();
      return vm;
    });
  },
  data() {
    return {
      busy: false,
      publisher: null,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadData();
        },
      },
      filters: {
        keyword: '',
      },
      filtersExpanded: false,
      records: [],
    };
  },
  methods: {
    toggleFilters: function () {
      this.filtersExpanded = !this.filtersExpanded;
    },
    async loadData() {
      this.busy = true;
      let params = {
        page: this.paginate.page,
        page_size: this.paginate.limit,
        api_key: this.publisher.api_key,
        keyword: this.filters.keyword,
      };
      try {
        let url = process.env.VUE_APP_API_BASE_URL + 'api/v2.0/getOffers';
        let resp = await Vue.http.get(url, { params });
        this.records = resp.body.offers;
        this.paginate.total = resp.body.total;
        this.paginate.numPages = resp.body.num_pages;
      } catch (e) {
        this.$ovNotify.error(e);
      }
      this.busy = false;
    },
    async autoClean() {
      if (this.busy) {
        return;
      }
      let confirmed = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to auto-clean this feed',
      );
      if (confirmed) {
        this.busy = true;
        try {
          await this.$ovReq.post('publisher/autoClean', { id: this.publisher.id });
          this.busy = false;
          this.load();
        } catch (e) {
          this.busy = false;
        }
      }
    },
  },
  created() {},
};
</script>
